
import { defineComponent, ref } from "vue";
import { cloneDeep } from "lodash";
import { format } from "date-fns";
import { Avatar3, Tag3 } from "@/uikit";
import { useRoute } from "@/helpers";
import { ERoutesName } from "@/router";

export default defineComponent({
  components: { Avatar3, Tag3 },
  props: ["diseaseSituation"],
  setup(props) {
    const { push } = useRoute();
    const handleViewFull = (link: string) =>
      push({ name: ERoutesName.VIEW_IMAGE, query: { link } });
    const readMore = ref<boolean>(false);
    const limit = ref<number>(100);

    const showMore = () => {
      limit.value = 0;
      readMore.value = false;
    };

    const detailComment = (id: string) => {
      push({ name: ERoutesName.DISEASE_DETAIL, params: { id } });
    };

    const fromatString = (text: string) => {
      if (!text) return "";
      if (!limit.value) return text;
      if (text.length < limit.value) return text;
      readMore.value = true;
      const txt = text.slice(0, limit.value);
      return txt + "...";
    };
    return {
      format,
      handleViewFull,
      fromatString,
      readMore,
      limit,
      showMore,
      detailComment
    };
  }
});

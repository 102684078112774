
import { defineComponent } from "vue";
import { Avatar3 } from "@/uikit";
import { ERoutesName } from "@/router";
import { useRoute } from "@/helpers";
import { formatDistanceToNow } from "date-fns";
import VI from "date-fns/locale/vi";

export default defineComponent({
  components: { Avatar3 },
  props: ["comment"],
  setup() {
    const { push } = useRoute();
    const handleViewFull = (link: string) =>
      push({ name: ERoutesName.VIEW_IMAGE, query: { link } });
    return { handleViewFull, formatDistanceToNow, VI };
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-322df594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comment-item" }
const _hoisted_2 = { class: "avatar mr-2" }
const _hoisted_3 = { class: "comment-item__content" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "created-at" }
const _hoisted_6 = { class: "message" }
const _hoisted_7 = {
  key: 0,
  class: "list-image mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar3 = _resolveComponent("Avatar3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Avatar3, {
        round: true,
        width: "40px",
        height: "40px",
        avatar: _ctx.comment?.commenterAvatar
      }, null, 8, ["avatar"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.comment.commenterName), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatDistanceToNow(new Date(_ctx.comment.createdAt), { locale: _ctx.VI })), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.comment.message), 1),
      (_ctx.comment?.listImages.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment?.listImages, (img, index) => {
              return (_openBlock(), _createBlock(_component_Avatar3, {
                width: "80px",
                height: "80px",
                radius: "5px",
                class: "mr-1",
                key: index,
                avatar: img,
                onClick: ($event: any) => (_ctx.handleViewFull(img))
              }, null, 8, ["avatar", "onClick"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { defineComponent, ref, watchEffect } from "vue";
import { useRoute, useGetAll } from "@/helpers";
import { DISEADE_COMMENT_FRAGMENT } from "@/graphql";
import Loading from "@/components/loading/index.vue";
import DiseaseItem from "../components/disease-item.vue";
import CommentItem from "../components/comment-item.vue";

export default defineComponent({
  components: { Loading, DiseaseItem, CommentItem },
  setup() {
    const { query, back } = useRoute();
    const situationId = query.id;
    const diseaseComments = ref<any[]>([]);

    const { data, loading } = useGetAll(
      "DiseaseComment",
      DISEADE_COMMENT_FRAGMENT,
      { filter: { situationId }, order: { createdAt: -1 } }
    );

    watchEffect(() => {
      if (data.value) {
        diseaseComments.value = data.value;
      }
    });
    return { loading, diseaseComments, back };
  }
});
